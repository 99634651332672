<script setup lang="ts">
import ChevronRight from '@/assets/svg/chevron-right.svg?component'
import ChevronDown from '@/assets/svg/chevron-down.svg?component'
import ChevronUp from '@/assets/svg/chevron-up.svg?component'
import Download from '@/assets/svg/download_file_square_1.svg?component'
import LinkIcon from '@/assets/svg/link.svg?component'
import TwitterIcon from '@/assets/svg/twitter.svg?component'
import LinkedInIcon from '@/assets/svg/linkedin.svg?component'
import MeetingIcon from '@/assets/svg/user-multiple.svg?component'
const props = defineProps({
  icon: {
    type: String as PropType<ButtonIcon>,
    default: 'chevron',
  },
})
</script>

<template>
  <ChevronRight v-if="props.icon === 'chevron'" aria-hidden="true" />
  <ChevronDown v-if="props.icon === 'chevron-down'" aria-hidden="true" />
  <ChevronUp v-if="props.icon === 'chevron-up'" aria-hidden="true" />
  <Download v-else-if="props.icon === 'download'" aria-hidden="true" />
  <LinkIcon v-else-if="props.icon === 'link'" aria-hidden="true" />
  <TwitterIcon v-else-if="props.icon === 'twitter'" aria-hidden="true" />
  <LinkedInIcon v-else-if="props.icon === 'linkedin'" aria-hidden="true" />
  <MeetingIcon v-else-if="props.icon === 'meeting'" aria-hidden="true" />
</template>
